import React from 'react';
import AppLayout from '../components/AppLayout';

export default function NotFound() {
  return (
    <AppLayout>
      <h2>404</h2>
      <p>Sorry, that page doesn't exist</p>
    </AppLayout>
  );
}
